var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid,
          pristine = _ref.pristine;
        return [_c('b-card', {
          staticClass: "border my-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('customer.tabInfo')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0"
        }, [_c('b-form', [_c('b-row', {
          staticClass: "mt-1"
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex mb-50"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "InfoIcon",
            "size": "19"
          }
        }), _c('h4', {
          staticClass: "mb-0 ml-50"
        }, [_vm._v(" " + _vm._s(_vm.$t('customer.accountInfoText')) + " ")])], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Last Name",
            "rules": "required|max:30"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": "".concat(_vm.$t('customer.lastName'), " (*)"),
                  "label-for": "last-name"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "last-name",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "maxlength": "30",
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('customer.placeholderLastName')
                },
                model: {
                  value: _vm.customerDataToAdd.lastName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.customerDataToAdd, "lastName", $$v);
                  },
                  expression: "customerDataToAdd.lastName"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "First Name",
            "rules": "required|max:30"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": "".concat(_vm.$t('customer.firstName'), " (*)"),
                  "label-for": "first-name"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "first-name",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "maxlength": "30",
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('customer.placeholderFirstName')
                },
                model: {
                  value: _vm.customerDataToAdd.firstName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.customerDataToAdd, "firstName", $$v);
                  },
                  expression: "customerDataToAdd.firstName"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Email",
            "rules": "required|email|isUnique:customer,email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": "".concat(_vm.$t('customer.email'), " (*)"),
                  "label-for": "email"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "email",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimInput,
                  "debounce": 300,
                  "placeholder": _vm.$t('customer.placeholderEmail')
                },
                model: {
                  value: _vm.customerDataToAdd.emailAddress,
                  callback: function callback($$v) {
                    _vm.$set(_vm.customerDataToAdd, "emailAddress", $$v);
                  },
                  expression: "customerDataToAdd.emailAddress"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Phone",
            "rules": "required|phoneNumber|isUnique:customer,phonenumber"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": "".concat(_vm.$t('customer.phoneNumber'), " (*)"),
                  "label-for": "phone"
                }
              }, [_c('div', {
                staticClass: "d-flex"
              }, [_c('b-form-input', {
                attrs: {
                  "id": "phone",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimInput,
                  "debounce": 300,
                  "placeholder": _vm.$t('customer.placeholderPhoneNumber')
                },
                model: {
                  value: _vm.customerDataToAdd.phoneNumber,
                  callback: function callback($$v) {
                    _vm.$set(_vm.customerDataToAdd, "phoneNumber", $$v);
                  },
                  expression: "customerDataToAdd.phoneNumber"
                }
              }), _c('b-button', {
                staticClass: "ml-50 px-1 text-nowrap",
                attrs: {
                  "variant": "outline-info",
                  "disabled": !_vm.isActiveAddMorePhone || !_vm.getValidationState(validationContext)
                },
                on: {
                  "click": _vm.addMorePhoneHandle
                }
              }, [_c('feather-icon', {
                attrs: {
                  "icon": "PlusIcon"
                }
              })], 1)], 1), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "dob",
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": "".concat(_vm.$t('customer.dob')),
                  "label-for": "dob"
                }
              }, [_c('b-form-datepicker', {
                staticClass: "form-control",
                attrs: {
                  "id": "dob",
                  "name": "dob",
                  "placeholder": _vm.$t('customer.placeholderDoB'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "date-format-options": {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  },
                  "max": new Date(),
                  "min": new Date(1920, 0, 1),
                  "show-decade-nav": "",
                  "hide-header": "",
                  "locale": "vi",
                  "boundary": "window",
                  "no-flip": ""
                },
                model: {
                  value: _vm.customerDataToAdd.birthDay,
                  callback: function callback($$v) {
                    _vm.$set(_vm.customerDataToAdd, "birthDay", $$v);
                  },
                  expression: "customerDataToAdd.birthDay"
                }
              })], 1), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6",
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Gender",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": "".concat(_vm.$t('customer.gender'), " (*)"),
                  "label-for": "gender",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_c('v-select', {
                attrs: {
                  "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                  "options": _vm.genderOptions,
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": false,
                  "input-id": "gender",
                  "placeholder": _vm.$t('customer.placeholderSelectGender')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(_ref2) {
                    var value = _ref2.value;
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(value)) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(_ref3) {
                    var value = _ref3.value;
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(value)) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('customer.noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.customerDataToAdd.gender,
                  callback: function callback($$v) {
                    _vm.$set(_vm.customerDataToAdd, "gender", $$v);
                  },
                  expression: "customerDataToAdd.gender"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1)], 1), _vm._l(_vm.customerDataToAdd.backupPhoneNumbers, function (phone, index) {
          return _c('b-col', {
            key: index,
            attrs: {
              "cols": "12",
              "sm": "6",
              "md": "4"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Backup Phone",
              "rules": "required|phoneNumber|isUnique:customer,phonenumber|duplicatePhone:".concat(_vm.customerDataToAdd.phoneNumber, ",").concat(_vm.customerDataToAdd.backupPhoneNumbers.toString())
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  attrs: {
                    "label": "".concat(_vm.$t('customer.backupPhoneNumber'), " ").concat(index + 1, " (*)"),
                    "label-for": "backup-phone-".concat(index)
                  }
                }, [_c('div', {
                  staticClass: "d-flex"
                }, [_c('b-form-input', {
                  attrs: {
                    "id": "backup-phone-".concat(index),
                    "state": _vm.getValidationState(validationContext) === false ? false : null,
                    "formatter": _vm.trimInput,
                    "debounce": 300,
                    "placeholder": _vm.$t('customer.placeholderPhoneNumber')
                  },
                  model: {
                    value: _vm.customerDataToAdd.backupPhoneNumbers[index],
                    callback: function callback($$v) {
                      _vm.$set(_vm.customerDataToAdd.backupPhoneNumbers, index, $$v);
                    },
                    expression: "customerDataToAdd.backupPhoneNumbers[index]"
                  }
                }), _c('b-button', {
                  staticClass: "ml-50 px-50 text-nowrap",
                  attrs: {
                    "variant": "flat-danger"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.removePhoneHandle(index);
                    }
                  }
                }, [_c('feather-icon', {
                  attrs: {
                    "icon": "XIcon"
                  }
                })], 1)], 1), _c('b-form-invalid-feedback', {
                  attrs: {
                    "state": _vm.getValidationState(validationContext) === false ? false : null
                  }
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1);
        }), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Description",
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": _vm.$t('customer.description'),
                  "label-for": "description"
                }
              }, [_c('b-form-textarea', {
                staticClass: "overflow-auto",
                attrs: {
                  "id": "description",
                  "placeholder": _vm.$t('customer.placeholderDescription'),
                  "rows": "2",
                  "max-rows": "4",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "no-resize": ""
                },
                model: {
                  value: _vm.customerDataToAdd.description,
                  callback: function callback($$v) {
                    _vm.$set(_vm.customerDataToAdd, "description", $$v);
                  },
                  expression: "customerDataToAdd.description"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex mb-50"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "MapPinIcon",
            "size": "19"
          }
        }), _c('h4', {
          staticClass: "mb-0 ml-50"
        }, [_vm._v(" " + _vm._s(_vm.$t('customer.address')) + " ")])], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "8"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Address Country",
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": _vm.$t('customer.country'),
                  "label-for": "addressCountry"
                }
              }, [_c('v-select', {
                attrs: {
                  "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                  "reduce": function reduce(country) {
                    return country.id;
                  },
                  "options": _vm.countryOptions,
                  "label": "name",
                  "placeholder": _vm.$t('customer.placeholderCountry')
                },
                on: {
                  "open": _vm.handleFetchCountry
                },
                scopedSlots: _vm._u([{
                  key: "no-options",
                  fn: function fn(_ref4) {
                    var search = _ref4.search,
                      searching = _ref4.searching;
                    return [searching ? [_vm._v(" " + _vm._s(_vm.$t('customer.noResultFund'))), _c('b', [_vm._v(_vm._s(search))])] : _vm.isLoadingCountry ? [_c('b-spinner', {
                      attrs: {
                        "variant": "primary",
                        "label": "Text Centered",
                        "small": "",
                        "tag": "span"
                      }
                    }), _c('span', {
                      staticClass: "pl-1"
                    }, [_vm._v(" " + _vm._s(_vm.$t('customer.loadingCountryText')) + " ")])] : _vm._e()];
                  }
                }], null, true),
                model: {
                  value: _vm.customerDataToAdd.addressCountry,
                  callback: function callback($$v) {
                    _vm.$set(_vm.customerDataToAdd, "addressCountry", $$v);
                  },
                  expression: "customerDataToAdd.addressCountry"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Address City",
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": _vm.$t('customer.city'),
                  "label-for": "addressCity"
                }
              }, [_c('v-select', {
                attrs: {
                  "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                  "label": "name",
                  "reduce": function reduce(country) {
                    return country.id;
                  },
                  "placeholder": _vm.$t('customer.placeholderCity'),
                  "options": _vm.cityOptions
                },
                scopedSlots: _vm._u([{
                  key: "no-options",
                  fn: function fn(_ref5) {
                    var search = _ref5.search,
                      searching = _ref5.searching;
                    return [searching ? [_vm._v(" " + _vm._s(_vm.$t('customer.noResultFund'))), _c('b', [_vm._v(_vm._s(search))])] : _vm.isLoadingCity ? [_c('b-spinner', {
                      attrs: {
                        "variant": "primary",
                        "label": "Text Centered",
                        "small": "",
                        "tag": "span"
                      }
                    }), _c('span', {
                      staticClass: "pl-1"
                    }, [_vm._v(" " + _vm._s(_vm.$t('customer.loadingCityText')) + " ")])] : [_c('span', [_vm._v(_vm._s(_vm.$t('customer.needSelect_1')) + " "), _c('b', [_vm._v(_vm._s(_vm.$t('customer.country')))]), _vm._v(" " + _vm._s(_vm.$t('customer.needSelect_2')))])]];
                  }
                }, {
                  key: "option",
                  fn: function fn(_ref6) {
                    var preName = _ref6.preName,
                      name = _ref6.name;
                    return [_c('div', {
                      staticClass: "d-flex align-items-center"
                    }, [_c('span', {
                      staticClass: "d-block text-nowrap"
                    }, [_vm._v(" " + _vm._s(preName) + " " + _vm._s(name) + " ")])])];
                  }
                }], null, true),
                model: {
                  value: _vm.customerDataToAdd.addressCity,
                  callback: function callback($$v) {
                    _vm.$set(_vm.customerDataToAdd, "addressCity", $$v);
                  },
                  expression: "customerDataToAdd.addressCity"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Address District",
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": _vm.$t('customer.district'),
                  "label-for": "addressDistrict"
                }
              }, [_c('v-select', {
                attrs: {
                  "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                  "label": "name",
                  "reduce": function reduce(country) {
                    return country.id;
                  },
                  "placeholder": _vm.$t('customer.placeholderDistrict'),
                  "options": _vm.districtOptions
                },
                scopedSlots: _vm._u([{
                  key: "no-options",
                  fn: function fn(_ref7) {
                    var search = _ref7.search,
                      searching = _ref7.searching;
                    return [searching ? [_vm._v(" " + _vm._s(_vm.$t('customer.noResultFund'))), _c('b', [_vm._v(_vm._s(search))])] : _vm.isLoadingDistrict ? [_c('b-spinner', {
                      attrs: {
                        "variant": "primary",
                        "label": "Text Centered",
                        "small": "",
                        "tag": "span"
                      }
                    }), _c('span', {
                      staticClass: "pl-1"
                    }, [_vm._v(" " + _vm._s(_vm.$t('customer.placeholderDistrict')) + " ")])] : [_c('span', [_vm._v(_vm._s(_vm.$t('customer.needSelect_1')) + " "), _c('b', [_vm._v(_vm._s(_vm.$t('customer.city')))]), _vm._v(" " + _vm._s(_vm.$t('customer.needSelect_2')))])]];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(_ref8) {
                    var preName = _ref8.preName,
                      name = _ref8.name;
                    return [_c('div', {
                      staticClass: "d-flex align-items-center"
                    }, [_c('span', {
                      staticClass: "d-block text-nowrap"
                    }, [_vm._v(" " + _vm._s(preName) + " " + _vm._s(name) + " ")])])];
                  }
                }, {
                  key: "option",
                  fn: function fn(_ref9) {
                    var preName = _ref9.preName,
                      name = _ref9.name;
                    return [_c('div', {
                      staticClass: "d-flex align-items-center"
                    }, [_c('span', {
                      staticClass: "d-block text-nowrap"
                    }, [_vm._v(" " + _vm._s(preName) + " " + _vm._s(name) + " ")])])];
                  }
                }], null, true),
                model: {
                  value: _vm.customerDataToAdd.addressDistrict,
                  callback: function callback($$v) {
                    _vm.$set(_vm.customerDataToAdd, "addressDistrict", $$v);
                  },
                  expression: "customerDataToAdd.addressDistrict"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Address Ward",
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": _vm.$t('customer.ward'),
                  "label-for": "addressWard"
                }
              }, [_c('v-select', {
                attrs: {
                  "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                  "label": "name",
                  "reduce": function reduce(country) {
                    return country.id;
                  },
                  "placeholder": _vm.$t('customer.placeholderWard'),
                  "options": _vm.wardOptions
                },
                scopedSlots: _vm._u([{
                  key: "no-options",
                  fn: function fn(_ref10) {
                    var search = _ref10.search,
                      searching = _ref10.searching;
                    return [searching ? [_vm._v(" " + _vm._s(_vm.$t('customer.noResultFund'))), _c('b', [_vm._v(_vm._s(search))])] : _vm.isLoadingWard ? [_c('b-spinner', {
                      attrs: {
                        "variant": "primary",
                        "label": "Text Centered",
                        "small": "",
                        "tag": "span"
                      }
                    }), _c('span', {
                      staticClass: "pl-1"
                    }, [_vm._v(" " + _vm._s(_vm.$t('customer.loadingWardText')) + " ")])] : [_c('span', [_vm._v(_vm._s(_vm.$t('customer.needSelect_1')) + " "), _c('b', [_vm._v(_vm._s(_vm.$t('customer.district')))]), _vm._v(" " + _vm._s(_vm.$t('customer.needSelect_2')))])]];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(_ref11) {
                    var preName = _ref11.preName,
                      name = _ref11.name;
                    return [_c('div', {
                      staticClass: "d-flex align-items-center"
                    }, [_c('span', {
                      staticClass: "d-block text-nowrap"
                    }, [_vm._v(" " + _vm._s(preName) + " " + _vm._s(name) + " ")])])];
                  }
                }, {
                  key: "option",
                  fn: function fn(_ref12) {
                    var preName = _ref12.preName,
                      name = _ref12.name;
                    return [_c('div', {
                      staticClass: "d-flex align-items-center"
                    }, [_c('span', {
                      staticClass: "d-block text-nowrap"
                    }, [_vm._v(" " + _vm._s(preName) + " " + _vm._s(name) + " ")])])];
                  }
                }], null, true),
                model: {
                  value: _vm.customerDataToAdd.addressWard,
                  callback: function callback($$v) {
                    _vm.$set(_vm.customerDataToAdd, "addressWard", $$v);
                  },
                  expression: "customerDataToAdd.addressWard"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Address Street",
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": _vm.$t('customer.street'),
                  "label-for": "address-street"
                }
              }, [_c('b-form-textarea', {
                staticClass: "overflow-auto",
                attrs: {
                  "id": "address-street",
                  "placeholder": _vm.$t('customer.placeholderStreet'),
                  "rows": ['xs', 'sm'].includes(_vm.$store.getters['app/currentBreakPoint']) ? 2 : 4,
                  "max-rows": "4",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "no-resize": ""
                },
                model: {
                  value: _vm.customerDataToAdd.addressStreet,
                  callback: function callback($$v) {
                    _vm.$set(_vm.customerDataToAdd, "addressStreet", $$v);
                  },
                  expression: "customerDataToAdd.addressStreet"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1)], 1)], 2)], 1)], 1)], 1), _c('b-card', {
          staticClass: "border my-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1",
            "body-class": "pb-1 pt-50"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" Mạng xã hội ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0"
        }, [_vm.customerDataToAdd.socialAccounts.length > 0 ? _c('div', _vm._l(_vm.customerDataToAdd.socialAccounts, function (item, index) {
          return _c('b-row', {
            key: index,
            staticClass: "mx-0",
            attrs: {
              "no-gutters": ""
            }
          }, [_c('b-col', {
            staticClass: "pr-md-1",
            attrs: {
              "cols": "12",
              "md": "4"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "M\u1EA1ng x\xE3 h\u1ED9i #".concat(index + 1)
            }
          }, [_c('b-form-input', {
            staticClass: "font-weight-bold",
            attrs: {
              "id": "number-".concat(index),
              "placeholder": "Nhập vào"
            },
            model: {
              value: item.type,
              callback: function callback($$v) {
                _vm.$set(item, "type", $$v);
              },
              expression: "item.type"
            }
          })], 1)], 1), _c('b-col', {
            attrs: {
              "cols": "12",
              "md": "7"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Địa chỉ (Url)"
            }
          }, [_c('b-form-input', {
            staticClass: "font-weight-bold",
            attrs: {
              "id": "url-".concat(index),
              "placeholder": "Nhập vào"
            },
            model: {
              value: item.url,
              callback: function callback($$v) {
                _vm.$set(item, "url", $$v);
              },
              expression: "item.url"
            }
          })], 1)], 1), _c('b-col', {
            staticClass: "mb-50 d-flex align-items-end justify-content-end",
            attrs: {
              "cols": "12",
              "md": "1"
            }
          }, [_c('b-button', {
            directives: [{
              name: "ripple",
              rawName: "v-ripple.400",
              value: 'rgba(234, 84, 85, 0.15)',
              expression: "'rgba(234, 84, 85, 0.15)'",
              modifiers: {
                "400": true
              }
            }],
            staticClass: "p-50",
            staticStyle: {
              "margin-bottom": "7px"
            },
            attrs: {
              "variant": "flat-danger"
            },
            on: {
              "click": function click($event) {
                return _vm.handleDeleteItem(index);
              }
            }
          }, [_c('feather-icon', {
            attrs: {
              "icon": "XIcon",
              "size": "20"
            }
          })], 1)], 1), _vm.customerDataToAdd.socialAccounts.length > 1 ? _c('b-col', {
            attrs: {
              "cols": "12"
            }
          }, [_c('hr', {
            staticClass: "mt-0"
          })]) : _vm._e()], 1);
        }), 1) : _vm._e(), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "py-50 px-2 rounded d-flex-center",
          class: _vm.customerDataToAdd.socialAccounts.length === 0 ? 'mt-1' : '',
          attrs: {
            "variant": "flat-primary"
          },
          on: {
            "click": _vm.handleAddItem
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PlusIcon",
            "size": "20"
          }
        }), _c('span', {
          staticClass: "ml-25"
        }, [_vm._v("Thêm mạng xã hội")])], 1)], 1)], 1), _c('div', {
          staticClass: "d-flex mt-1 justify-content-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "secondary"
          },
          on: {
            "click": _vm.backHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('customer.back')) + " ")]), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "danger",
            "disabled": pristine
          },
          on: {
            "click": _vm.clearHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('customer.clear')) + " ")]), _c('b-button', {
          attrs: {
            "variant": "info",
            "disabled": invalid || !_vm.canAccess('customer.createCustomer')
          },
          on: {
            "click": _vm.nextHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('customer.next')) + " ")])], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }