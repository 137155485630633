<template>
  <b-card>
    <form-wizard
      ref="refFormWizard"
      :title="null"
      :subtitle="null"
      hide-buttons
      class="customer-form-wizard steps-transparent"
    >
      <!-- account detail tab -->
      <tab-content
        :title="$t('customer.Account')"
        icon="feather icon-user"
      >
        <customers-add-tab-account
          :customer-data.sync="customerData"
          @next-step="formWizardNextStep"
          @submit="submit"
        />
      </tab-content>

      <tab-content
        :title="$t('customer.Contact')"
        icon="feather icon-users"
      >
        <customers-add-tab-contact
          :customer-data.sync="customerData"
          @next-step="formWizardNextStep"
          @prev-step="formWizardPrevStep"
          @submit="submit"
        />
      </tab-content>
      <tab-content
        :title="$t('customer.historyBooking')"
        icon="feather icon-navigation"
      >
        {{ $t('customer.noInfo') }}
        <div>
          <b-button
            variant="secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="formWizardPrevStep"
          >
            {{ $t('customer.back') }}
          </b-button>
        </div>
      </tab-content>
    </form-wizard>
  </b-card>
</template>

<script>
import {
  BCard, BButton,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { onUnmounted, ref } from '@vue/composition-api'

import store from '@/store'
import { getUserData } from '@/api/auth/utils'

import { useRouter } from '@core/utils/utils'

import customerStoreModule from '@customer/customerStoreModule'
import useCustomerHandle from '@customer/useCustomerHandle'

import CustomersAddTabAccount from './CustomersAddTabAccount.vue'
import CustomersAddTabContact from './CustomersAddTabContact.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    BCard,
    BButton,

    // components
    CustomersAddTabAccount,
    CustomersAddTabContact,
  },
  setup() {
    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-customer'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })

    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }
    const formWizardPrevStep = () => {
      refFormWizard.value.prevTab()
    }

    const customerData = ref({
      // require
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
      creator: getUserData().employeeData.id,
      gender: 'MALE',
      // options
      photo: null,
      description: null,
      birthDay: null,
      addressStreet: null,
      addressCity: null,
      addressCountry: null,
      addressDistrict: null,
      addressWard: null,
      contacts: [],
      backupPhoneNumbers: null,
    })
    const { router } = useRouter()
    const { addCustomer } = useCustomerHandle()
    const submit = () => {
      const payload = {
        ...customerData.value,
        socialAccounts: customerData.value.socialAccounts.filter(i => i.type && i.url),
      }
      addCustomer(payload)
        .then(() => {
          router.push({ name: 'apps-customers-list' })
        }).catch(error => {
          console.error({ error })
        })
    }

    return {
      refFormWizard,
      formWizardNextStep,
      formWizardPrevStep,

      customerData,
      submit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.customer-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
