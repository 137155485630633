var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid,
          pristine = _ref.pristine;
        return [_c('b-form', {
          ref: "form"
        }, _vm._l(_vm.contactsList, function (contact, index) {
          return _c('b-row', {
            key: index,
            ref: "row",
            refInFor: true
          }, [_c('b-col', {
            attrs: {
              "cols": "12"
            }
          }, [_c('b-card', {
            staticClass: "border",
            attrs: {
              "header-tag": "header",
              "header-bg-variant": "light-info",
              "header-class": "py-75",
              "body-class": "pt-1 pb-50"
            },
            scopedSlots: _vm._u([{
              key: "header",
              fn: function fn() {
                return [_c('div', {
                  staticClass: "d-flex justify-content-between align-items-center w-100"
                }, [_c('h5', {
                  staticClass: "m-0"
                }, [_vm._v(" " + _vm._s(_vm.$t('customer.contact')) + " #" + _vm._s(index + 1) + " ")]), _c('b-button', {
                  staticClass: "p-25",
                  attrs: {
                    "variant": "outline-danger"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.onRemoveItemHandle(index);
                    }
                  }
                }, [_c('feather-icon', {
                  attrs: {
                    "icon": "XIcon"
                  }
                })], 1)], 1)];
              },
              proxy: true
            }], null, true)
          }, [_c('b-row', [_c('b-col', {
            attrs: {
              "cols": "12",
              "md": "6"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Last Name",
              "rules": "required|max:30"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  attrs: {
                    "label": "".concat(_vm.$t('customer.lastName'), " (*)"),
                    "label-for": "last-name"
                  }
                }, [_c('b-form-input', {
                  attrs: {
                    "id": "last-name",
                    "state": _vm.getValidationState(validationContext) === false ? false : null,
                    "maxlength": "30",
                    "lazy-formatter": "",
                    "formatter": _vm.trimInput,
                    "placeholder": _vm.$t('customer.placeholderLastName')
                  },
                  model: {
                    value: contact.lastName,
                    callback: function callback($$v) {
                      _vm.$set(contact, "lastName", $$v);
                    },
                    expression: "contact.lastName"
                  }
                }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1), _c('b-col', {
            attrs: {
              "md": "6"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "First Name",
              "rules": "required|max:30"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  attrs: {
                    "label": "".concat(_vm.$t('customer.firstName'), " (*)"),
                    "label-for": "first-name"
                  }
                }, [_c('b-form-input', {
                  attrs: {
                    "id": "first-name",
                    "state": _vm.getValidationState(validationContext) === false ? false : null,
                    "maxlength": "30",
                    "lazy-formatter": "",
                    "formatter": _vm.trimInput,
                    "placeholder": _vm.$t('customer.placeholderFirstName')
                  },
                  model: {
                    value: contact.firstName,
                    callback: function callback($$v) {
                      _vm.$set(contact, "firstName", $$v);
                    },
                    expression: "contact.firstName"
                  }
                }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1)], 1), _c('b-row', [_c('b-col', {
            attrs: {
              "cols": "12",
              "md": "6"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "dob",
              "rules": "required|dateFormat"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  attrs: {
                    "label": "".concat(_vm.$t('customer.dob'), " (*)"),
                    "label-for": "dob"
                  }
                }, [_c('b-form-datepicker', {
                  staticClass: "form-control",
                  attrs: {
                    "name": "dob",
                    "placeholder": _vm.$t('customer.placeholderDoB'),
                    "state": _vm.getValidationState(validationContext) === false ? false : null,
                    "date-format-options": {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    },
                    "min": new Date(1920, 0, 1),
                    "max": new Date(),
                    "show-decade-nav": "",
                    "hide-header": "",
                    "locale": "vi",
                    "no-flip": ""
                  },
                  on: {
                    "input": function input($event) {
                      return _vm.handleSelectBirthday(contact);
                    }
                  },
                  model: {
                    value: contact.birthDay,
                    callback: function callback($$v) {
                      _vm.$set(contact, "birthDay", $$v);
                    },
                    expression: "contact.birthDay"
                  }
                })], 1), _c('b-form-invalid-feedback', {
                  attrs: {
                    "state": _vm.getValidationState(validationContext) === false ? false : null
                  }
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
              }
            }], null, true)
          })], 1), _c('b-col', {
            attrs: {
              "cols": "12",
              "md": "6"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Type of Passenger",
              "rules": "required"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  attrs: {
                    "label": "".concat(_vm.$t('customer.passengerType')),
                    "label-for": "type-passenger"
                  }
                }, [_c('v-select', {
                  attrs: {
                    "id": "type-passenger",
                    "label": "label",
                    "options": _vm.typeOfPassengerOptions,
                    "reduce": function reduce(val) {
                      return val.value;
                    },
                    "disabled": true
                  },
                  scopedSlots: _vm._u([{
                    key: "selected-option",
                    fn: function fn(data) {
                      return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
                    }
                  }], null, true),
                  model: {
                    value: contact.type,
                    callback: function callback($$v) {
                      _vm.$set(contact, "type", $$v);
                    },
                    expression: "contact.type"
                  }
                }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1)], 1)], 1)], 1)], 1);
        }), 1), _c('b-button', {
          attrs: {
            "variant": "primary",
            "disabled": invalid || _vm.contactsList.length === 5
          },
          on: {
            "click": _vm.onAddNewHandle
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "PlusIcon"
          }
        }), _c('span', [_vm._v(_vm._s(_vm.$t('customer.addNew')))])], 1), _c('div', {
          staticClass: "d-flex mt-1 justify-content-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "secondary"
          },
          on: {
            "click": _vm.backHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('customer.back')) + " ")]), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "danger",
            "disabled": pristine && _vm.contactsList.length === 0
          },
          on: {
            "click": _vm.clearHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('customer.clear')) + " ")]), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "info",
            "disabled": invalid
          },
          on: {
            "click": _vm.saveHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('customer.save')) + " ")])], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }