<template>
  <section>
    <!-- Form Input -->
    <validation-observer
      #default="{invalid, pristine}"
      ref="refFormObserver"
    >
      <b-form ref="form">
        <!-- Row Loop -->
        <b-row
          v-for="(contact, index) in contactsList"
          :key="index"
          ref="row"
        >
          <b-col cols="12">
            <b-card
              header-tag="header"
              header-bg-variant="light-info"
              header-class="py-75"
              body-class="pt-1 pb-50"
              class="border"
            >
              <template #header>
                <div
                  class="d-flex justify-content-between align-items-center w-100"
                >
                  <h5 class="m-0">
                    {{ $t('customer.contact') }} #{{ index + 1 }}
                  </h5>

                  <b-button
                    variant="outline-danger"
                    class="p-25"
                    @click="onRemoveItemHandle(index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class=""
                    />
                  </b-button>
                </div>
              </template>
              <b-row>
                <!-- Last Name -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Last Name"
                    rules="required|max:30"
                  >
                    <b-form-group
                      :label="`${$t('customer.lastName')} (*)`"
                      label-for="last-name"
                    >
                      <b-form-input
                        id="last-name"
                        v-model="contact.lastName"
                        :state="getValidationState(validationContext) === false ? false : null"
                        maxlength="30"
                        lazy-formatter
                        :formatter="trimInput"
                        :placeholder="$t('customer.placeholderLastName')"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- FirstName -->
                <b-col md="6">
                  <validation-provider
                    #default="validationContext"
                    name="First Name"
                    rules="required|max:30"
                  >
                    <b-form-group
                      :label="`${$t('customer.firstName')} (*)`"
                      label-for="first-name"
                    >
                      <b-form-input
                        id="first-name"
                        v-model="contact.firstName"
                        :state="getValidationState(validationContext) === false ? false : null"
                        maxlength="30"
                        lazy-formatter
                        :formatter="trimInput"
                        :placeholder="$t('customer.placeholderFirstName')"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

              </b-row>
              <b-row>
                <!-- Date Of Birth -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="dob"
                    rules="required|dateFormat"
                  >
                    <b-form-group
                      :label="`${$t('customer.dob')} (*)`"
                      label-for="dob"
                    >
                      <b-form-datepicker
                        v-model="contact.birthDay"
                        name="dob"
                        class="form-control"
                        :placeholder="$t('customer.placeholderDoB')"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :date-format-options="{
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        }"
                        :min="new Date(1920, 0, 1)"
                        :max="new Date()"
                        show-decade-nav
                        hide-header
                        locale="vi"
                        no-flip
                        @input="handleSelectBirthday(contact)"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback
                      :state="
                        getValidationState(validationContext) === false
                          ? false
                          : null
                      "
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>

                <!-- Type of passager -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Type of Passenger"
                    rules="required"
                  >
                    <b-form-group
                      :label="`${$t('customer.passengerType')}`"
                      label-for="type-passenger"
                    >
                      <v-select
                        id="type-passenger"
                        v-model="contact.type"
                        label="label"
                        :options="typeOfPassengerOptions"
                        :reduce="(val) => val.value"
                        :disabled="true"
                      >
                        <template #selected-option="data">
                          <span>
                            {{ $t(data.label) }}
                          </span>
                        </template>
                      </v-select>

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-form>

      <b-button
        variant="primary"
        :disabled="invalid || contactsList.length === 5"
        @click="onAddNewHandle"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>{{ $t('customer.addNew') }}</span>
      </b-button>

      <div class="d-flex mt-1 justify-content-center">
        <!-- Back button -->
        <b-button
          variant="secondary"
          class="mr-1"
          @click="backHandle"
        >
          {{ $t('customer.back') }}
        </b-button>

        <!-- Clear button -->
        <b-button
          variant="danger"
          class="mr-1"
          :disabled="pristine && contactsList.length === 0"
          @click="clearHandle"
        >
          {{ $t('customer.clear') }}
        </b-button>

        <!-- Save button -->
        <b-button
          variant="info"
          class="mr-1"
          :disabled="invalid"
          @click="saveHandle"
        >
          {{ $t('customer.save') }}
        </b-button>

        <!-- Next button -->
        <!-- <b-button
          variant="warning"
          class=""
          :disabled="invalid || true"
          @click="nextHandle"
        >
          {{ $t('next') }}
        </b-button> -->
      </div>
    </validation-observer>
  </section>
</template>

<script>
import {
  // BInputGroup, BInputGroupAppend,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BRow,
  BCol,
  BCard,
  BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  ref,
} from '@vue/composition-api'
import vSelect from 'vue-select'

import { typeOfPassengerOptions } from '@/constants/selectOptions'

import formValidation from '@core/comp-functions/forms/form-validation'
import { trimInput } from '@core/comp-functions/forms/formatter-input'

import useCustomerHandle from '@customer/useCustomerHandle'

import { required, dateFormat } from '@validations'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BCard,
    BFormDatepicker,
    // BInputGroup,
    // BInputGroupAppend,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    customerData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const blankContact = {
      firstName: '',
      lastName: '',
      birthDay: '',
      type: '',
    }

    const contactsList = ref([JSON.parse(JSON.stringify(blankContact))])

    // const customerData = ref(JSON.parse(JSON.stringify(blankCustomerData)))
    const resetContactList = () => {
      contactsList.value = []
    }
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetContactList,
    )
    const { calculateAge } = useCustomerHandle()

    // handle select type contact
    function handleSelectBirthday(contact) {
      if (contact) contact.type = calculateAge(contact.birthDay)
    }

    function onAddNewHandle() {
      contactsList.value.push({ ...blankContact })
    }

    function onRemoveItemHandle(index) {
      contactsList.value.splice(index, 1)
    }

    function saveHandle() {
      emit('update:customer-data', {
        ...props.customerData,
        contacts: [...contactsList.value],
      })
      emit('submit')
    }

    function nextHandle() {
      emit('update:customer-data', {
        ...props.customerData,
        contacts: [...contactsList.value],
      })
      emit('next-step')
    }

    function backHandle() {
      // resetForm()
      emit('prev-step')
    }
    function clearHandle() {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.clearForm'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            resetForm()
          }
        })
    }

    return {
      // Data and ref
      contactsList,
      refFormObserver,
      getValidationState,

      // Validations
      required,
      dateFormat,

      // Methods
      backHandle,
      clearHandle,
      nextHandle,
      saveHandle,
      onAddNewHandle,
      onRemoveItemHandle,

      typeOfPassengerOptions,
      handleSelectBirthday,
      trimInput,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
// @import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}
</style>
