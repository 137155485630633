var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('form-wizard', {
    ref: "refFormWizard",
    staticClass: "customer-form-wizard steps-transparent",
    attrs: {
      "title": null,
      "subtitle": null,
      "hide-buttons": ""
    }
  }, [_c('tab-content', {
    attrs: {
      "title": _vm.$t('customer.Account'),
      "icon": "feather icon-user"
    }
  }, [_c('customers-add-tab-account', {
    attrs: {
      "customer-data": _vm.customerData
    },
    on: {
      "update:customerData": function updateCustomerData($event) {
        _vm.customerData = $event;
      },
      "update:customer-data": function updateCustomerData($event) {
        _vm.customerData = $event;
      },
      "next-step": _vm.formWizardNextStep,
      "submit": _vm.submit
    }
  })], 1), _c('tab-content', {
    attrs: {
      "title": _vm.$t('customer.Contact'),
      "icon": "feather icon-users"
    }
  }, [_c('customers-add-tab-contact', {
    attrs: {
      "customer-data": _vm.customerData
    },
    on: {
      "update:customerData": function updateCustomerData($event) {
        _vm.customerData = $event;
      },
      "update:customer-data": function updateCustomerData($event) {
        _vm.customerData = $event;
      },
      "next-step": _vm.formWizardNextStep,
      "prev-step": _vm.formWizardPrevStep,
      "submit": _vm.submit
    }
  })], 1), _c('tab-content', {
    attrs: {
      "title": _vm.$t('customer.historyBooking'),
      "icon": "feather icon-navigation"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('customer.noInfo')) + " "), _c('div', [_c('b-button', {
    attrs: {
      "variant": "secondary",
      "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
    },
    on: {
      "click": _vm.formWizardPrevStep
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('customer.back')) + " ")])], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }